import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: '/matlogin',
        pathMatch: 'full'
      },
      {
        path: 'salesorders',
        loadChildren: () => import('./salesorder/sales-order-list/sales-order-list.module').then(module => module.SalesOrderListModule)
      },
      {
        path: 'sample-page',
        loadChildren: () => import('./authpages/sample-page/sample-page.module').then(module => module.SamplePageModule)
      },{
        path: 'sales-order',
        loadChildren: () => import('./salesorder/sales-order/sales-order.module').then(module => module.SalesOrderModule)
      },
      {
        path: 'orderitems',
        loadChildren: () => import('./salesorder/order-items/order-items.module').then(module => module.OrderItemsModule)
      },
      {
        path: 'itemdetail/:id',
        loadChildren: () => import('./salesorder/item-detail/item-detail.module').then(module => module.ItemDetailModule)
      },
      {
        path: 'edititemdetail/:id',
        loadChildren: () => import('./salesorder/item-detail-edit/item-detail-edit.module').then(module => module.ItemDetailEditModule)
      },
      {
        path: 'wishlist',
        loadChildren: () => import('./salesorder/wishlist/wishlist.module').then(module => module.WishlistModule)
      },
      {
        path: 'cart',
        loadChildren: () => import('./salesorder/cart/cart.module').then(module => module.CartModule)
      },
      {
        path: 'demo-pos',
        loadChildren: () => import('./point-of-sale/demopos/demopos.module').then(module => module.DemoposModule)
      },
      {
        path: 'point-of-sales',
        loadChildren: () => import('./point-of-sale/pos/pos.module').then(module => module.PosModule)
      },
      {
        path: 'touch-pos',
        loadChildren: () => import('./point-of-sale/touchpos/touchpos.module').then(module => module.TouchposModule)
      },
      {
        path: 'master',
        loadChildren: () => import('./authpages/sample-page/sample-page.module').then(module => module.SamplePageModule)
      },
      {
        path: 'transaction',
        loadChildren: () => import('./authpages/sample-page/sample-page.module').then(module => module.SamplePageModule)
      },
      {
        path: 'metal-manufacture',
        loadChildren: () => import('./authpages/sample-page/sample-page.module').then(module => module.SamplePageModule)
      },
      {
        path: 'diamond-manufacture',
        loadChildren: () => import('./authpages/sample-page/sample-page.module').then(module => module.SamplePageModule)
      },
      {
        path: 'pos',
        loadChildren: () => import('./authpages/sample-page/sample-page.module').then(module => module.SamplePageModule)
      },
      {
        path: 'payroll',
        loadChildren: () => import('./authpages/sample-page/sample-page.module').then(module => module.SamplePageModule)
      },
      {
        path: 'inventory-reports',
        loadChildren: () => import('./authpages/sample-page/sample-page.module').then(module => module.SamplePageModule)
      },
      {
        path: 'financial-reports',
        loadChildren: () => import('./authpages/sample-page/sample-page.module').then(module => module.SamplePageModule)
      },
      {
        path: 'utilities',
        loadChildren: () => import('./authpages/sample-page/sample-page.module').then(module => module.SamplePageModule)
      },
      {
        path: 'exit',
        loadChildren: () => import('./authpages/sample-page/sample-page.module').then(module => module.SamplePageModule)
      }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [ {
      path: 'login',
      loadChildren: () => import('./authpages/auth-signin-v2/auth-signin-v2.module').then(module => module.AuthSigninV2Module)
    },{
      path: 'matlogin',
      loadChildren: () => import('./authpages/mat-auth-signin-v2/mat-auth-signin-v2.module').then(module => module.MatAuthSigninV2Module)
    }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
