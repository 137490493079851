<div class="row">
    <div class="col-xl-12">
        <table class="tabletd_gray">
            <tr>
                <td> <b>Invoice</b> </td>
                <td>Date : 09/09/2021</td>
                <td>Voucher Type : VOC </td>
                <td>Voucher Number: #123123123</td>
            </tr>
        </table>

    </div>
    <div class="col-xl-12">
        <div class="col-xl-12">
            <mat-tab-group>
                <mat-tab label="Point Of Sales">
                    <div class="row">
                        <div class="col-xl-4 col-md-6">
                            <div class="row customer-details">
                                <div class="col-xl-4 col-md-4">Sales Man</div>
                                <div class="col-xl-6 col-md-6"> <input type="text" class="form-control textboxcorner txtwidth_med" id="" aria-describedby="" placeholder="" /></div>
                                <div class="col-10 m-t-10 m-b-10">
                                    <b>Customer Details</b>
                                </div>
                                <div class="col-xl-4 col-md-4 ">Mobile Number</div>
                                <div class="col-xl-6 col-md-6 "> <input type="text" class="form-control textboxcorner txtwidth_med" id="" aria-describedby="" placeholder="" /></div>
                                <div class="col-xl-4 col-md-4 ">Customer</div>
                                <div class="col-xl-6 col-md-6 "> <input type="text" class="form-control textboxcorner txtwidth_med" id="" aria-describedby="" placeholder="" />
                                </div>
                                <div class="col-xl-4 col-md-4 ">Email</div>
                                <div class="col-xl-6 col-md-6 "> <input type="text" class="form-control textboxcorner txtwidth_med" id="" aria-describedby="" placeholder="" /></div>
                                <div class="col-xl-4 col-md-4 ">Nationality</div>
                                <div class="col-xl-6 col-md-6  m-b-10"> <input type="text" class="form-control textboxcorner txtwidth_med" id="" aria-describedby="" placeholder="" /></div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-3 todayrate m-t-10">
                            <table class="tabletd_gray">
                                <tr>
                                    <th>Karat </th>
                                    <th>Sales Price</th>
                                    <th>Purchase Price</th>
                                </tr>
                                <tr>
                                    <td> 18 </td>
                                    <td> 15.90 </td>
                                    <td> 15.70 </td>
                                </tr>
                                <tr>
                                    <td> 20 </td>
                                    <td> 15.90 </td>
                                    <td> 15.70 </td>
                                </tr>
                                <tr>
                                    <td> 21 </td>
                                    <td> 15.90 </td>
                                    <td> 15.70 </td>
                                </tr>
                                <tr>
                                    <td> 22 </td>
                                    <td> 15.90 </td>
                                    <td> 15.70 </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-xl-4 col-md-3 invoice m-t-10">
                            <div class="card">
                                <div class="card-header">
                                    <b>Receipts</b>
                                </div>
                                <div class="card-body">
                                    <p></p>
                                    <div class="row">
                                        <div class="col-md-12 text-right">
                                            <button type="submit" (click)="open(sales_payment_modal)" class="btn btn-secondary w-30">Add</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="..."></mat-tab>
            </mat-tab-group>
        </div>
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-9 m-t-10">
                    <div class="table-responsive">
                        <div class="content">
                            <div class="left">
                                <div class="wrapper">
                                    <div class="container">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>SNo
                                                        <div>SNo</div>
                                                    </th>
                                                    <th>Stock Code
                                                        <div>Stock Code</div>
                                                    </th>
                                                    <th>Description
                                                        <div>Description</div>
                                                    </th>
                                                    <th class="text-center">Weight
                                                        <div>Weight</div>
                                                    </th>
                                                    <th class="text-center">Qty
                                                        <div>Qty</div>
                                                    </th>
                                                    <th class="text-right">Mkg Amount
                                                        <div>Mkg Amount</div>
                                                    </th>
                                                    <th class="text-right">Total Amount
                                                        <div>Total Amount</div>
                                                    </th>
                                                    <th class="text-right">Action
                                                        <div>Action</div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>HL8998</td>
                                                    <td>Gold Ring</td>
                                                    <td class="text-center">12</td>
                                                    <td class="text-center">3</td>
                                                    <td class="text-right">3400.00</td>
                                                    <td class="text-right">8900.00</td>
                                                    <td class="text-right">
                                                        <a href="" class="badge m-r-5 badge-warning"><i class="feather icon-edit"></i></a>
                                                        <a href="" class="badge m-r-5 badge-danger"><i class="feather icon-trash"></i></a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>DCV1812</td>
                                                    <td>Dimond Ring</td>
                                                    <td class="text-center">24</td>
                                                    <td class="text-center">3</td>
                                                    <td class="text-right">1600.00</td>
                                                    <td class="text-right">18000.00</td>
                                                    <td class="text-right">
                                                        <a href="" class="badge m-r-5 badge-warning"><i class="feather icon-edit"></i></a>
                                                        <a href="" class="badge m-r-5 badge-danger"><i class="feather icon-trash"></i></a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>AGE18966</td>
                                                    <td>Diamond Bangle</td>
                                                    <td class="text-center">9</td>
                                                    <td class="text-center">1</td>
                                                    <td class="text-right">1789.00</td>
                                                    <td class="text-right">1789.00</td>
                                                    <td class="text-right">
                                                        <a href="" class="badge m-r-5 badge-warning"><i class="feather icon-edit"></i></a>
                                                        <a href="" class="badge m-r-5 badge-danger"><i class="feather icon-trash"></i></a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>HL8998</td>
                                                    <td>Gold Ring</td>
                                                    <td class="text-center">16</td>
                                                    <td class="text-center">2</td>
                                                    <td class="text-right">2500.00</td>
                                                    <td class="text-right">5000.00</td>
                                                    <td class="text-right">
                                                        <a href="" class="badge m-r-5 badge-warning"><i class="feather icon-edit"></i></a>
                                                        <a href="" class="badge m-r-5 badge-danger"><i class="feather icon-trash"></i></a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>DCV1812</td>
                                                    <td>Dimond Ring</td>
                                                    <td class="text-center">20</td>
                                                    <td class="text-center">3</td>
                                                    <td class="text-right">3000.00</td>
                                                    <td class="text-right">9000.00</td>
                                                    <td class="text-right">
                                                        <a href="" class="badge m-r-5 badge-warning"><i class="feather icon-edit"></i></a>
                                                        <a href="" class="badge m-r-5 badge-danger"><i class="feather icon-trash"></i></a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>DCV1812</td>
                                                    <td>Dimond Ring</td>
                                                    <td class="text-center">24</td>
                                                    <td class="text-center">3</td>
                                                    <td class="text-right">6000.00</td>
                                                    <td class="text-right">18000.00</td>
                                                    <td class="text-right">
                                                        <a href="" class="badge m-r-5 badge-warning"><i class="feather icon-edit"></i></a>
                                                        <a href="" class="badge m-r-5 badge-danger"><i class="feather icon-trash"></i></a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>AGE18966</td>
                                                    <td>Diamond Bangle</td>
                                                    <td class="text-center">29</td>
                                                    <td class="text-center">3</td>
                                                    <td class="text-right">6000.00</td>
                                                    <td class="text-right">18000.00</td>
                                                    <td class="text-right">
                                                        <a href="" class="badge m-r-5 badge-warning"><i class="feather icon-edit"></i></a>
                                                        <a href="" class="badge m-r-5 badge-danger"><i class="feather icon-trash"></i></a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>8</td>
                                                    <td>HL8998</td>
                                                    <td>Gold Ring</td>
                                                    <td class="text-center">24</td>
                                                    <td class="text-center">3</td>
                                                    <td class="text-right">6000.00</td>
                                                    <td class="text-right">18000.00</td>
                                                    <td class="text-right">
                                                        <a href="" class="badge m-r-5 badge-warning"><i class="feather icon-edit"></i></a>
                                                        <a href="" class="badge m-r-5 badge-danger"><i class="feather icon-trash"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <div>
                                                            <input type="text" class="" id="" aria-describedby="" placeholder="" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <input type="text" class="" id="" aria-describedby="" placeholder="" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <input type="text" class="" id="" aria-describedby="" placeholder="" />
                                                        </div>
                                                    </td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3" style="padding-left:0px;">
                    <div class="col-12 m-t-10">Item Amount</div>
                    <div class="col-12"> <input type="text" class="form-control textboxcorner txtwidth_med" id="" aria-describedby="" placeholder="" /></div>
                    <div class="col-12 m-t-10">VAT Amount</div>
                    <div class="col-12 "> <input type="text" class="form-control textboxcorner txtwidth_med" id="" aria-describedby="" placeholder="" /></div>
                    <div class="col-12 m-t-10">Gross Amount</div>
                    <div class="col-12 "> <input type="text" class="form-control textboxcorner txtwidth_med" id="" aria-describedby="" placeholder="" /></div>
                    <div class="col-md-12 m-t-10 text-left">
                        <button type="submit" (click)="open(mymodal)" class="btn btn-secondary" style="width: 20px !important;
                                      padding: 3px 10px;
                                      padding-right: 21px;">
                          <i class="fa fa-plus"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 m-t-10">
            <div class="row">
                <div class="col-xl-4 col-md-4">
                    <div class="card">
                        <div class="card-header">
                            <b>Adjust Sales Return</b>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6">
                                    <input type="text" class="form-control textboxcorner" style="width:100%;" id="" aria-describedby="" placeholder="" />
                                </div>
                                <div class="col-6">
                                    <input type="text" class="form-control textboxcorner " style="width:100%;" id="" aria-describedby="" placeholder="" />
                                </div>
                            </div>

                            <div class="row">
                                <!--<div class="col-md-6">
                                        <button type="submit" class="btn btn-warning w-100">Save</button>
                                    </div>-->
                                <div class="col-md-12 text-right m-t-10">
                                    <button type="submit" (click)="open(adjust_sale_return_modal)" class="btn btn-secondary w-30">+</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-md-4">
                    <div class="card">
                        <div class="card-header">
                            <b>Scrap Exchange</b>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6">
                                    <input type="text" class="form-control textboxcorner" style="width:100%;" id="" aria-describedby="" placeholder="" />
                                </div>
                                <div class="col-6">
                                    <input type="text" class="form-control textboxcorner " style="width:100%;" id="" aria-describedby="" placeholder="" />
                                </div>
                            </div>
                            <div class="row">
                                <!--<div class="col-md-6">
                                        <button type="submit" class="btn btn-warning w-100">Save</button>
                                    </div>-->
                                <div class="col-md-12 text-right m-t-10">
                                    <button type="submit" (click)="open(oldgoldmodal)" class="btn btn-secondary w-30">+</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-md-4">
                    <div class="notification-card" style="border-radius: 10px; background: #0d4f9e">
                        <div class="row align-items-center">
                            <div class="col-12 notify-icon" style="height: 56px; padding: 14px; font-size: 20px">
                                <i class="fa fa-save"></i> SAVE
                            </div>
                        </div>
                    </div>

                    <div class="notification-card" style="margin-top:10px;border-radius: 10px; background: #0d4f9e">
                        <div class="row align-items-center">
                            <div class="col-12 notify-icon" style="height: 56px; padding: 14px; font-size: 20px">
                                <i class="fa fa-print"></i> PRINT
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>
<ng-template #mymodal let-modal modal-lg>
    <div *ngIf="showModal">
        <div class="modal-header" style="padding: 10px 20px">
            <h4 class="modal-title" id="modal-pos-metal">POS Metal Division Details Screen</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="bg-gray-card">
                        <div class="form-group row">
                            <label class="font-weight-bold col-form-label  col-xs-2 col-sm-2 col-md-2 col-lg-2 ">Stock Code</label>
                            <!-- <input type="text" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="stockcode" value="" placeholder="Stock Code" /> -->
                            <select class="form-control  col-xs-1 col-sm-1 col-md-1 col-lg-1" name="stockcode" id="stockcode" (change)="stockCodeChange($event)" [(ngModel)]="stockcode">
                                    <option value="G">G</option>
                                    <option value="D">D</option>
                                </select> &nbsp;
                            <!-- <select class="form-control  col-xs-4 col-sm-4 col-md-4 col-lg-4" name="stockcode" id="stockcode"  >
                                    <option value="G">Gold</option>
                                    <option value="D">Diamond</option>
                                </select> -->
                            <input type="text" class="form-control  col-xs-4 col-sm-4 col-md-4 col-lg-4" id="stockcode" value="" placeholder="Stock Code" /> &nbsp;
                            <input type="text" class="form-control  col-xs-4 col-sm-4 col-md-4 col-lg-4" id="stockcode" value="" placeholder="Stock Desc" />
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-12" style="padding:0px ​15px 20px 15p;">
                    <div class="alert alert-danger alert-dismissible"> Alert : Invalid Quantity </div>
                </div> -->
                <div class="col-md-6">
                    <!-- <div class="form-group row" style="padding-top: 70px;"> -->
                    <!-- <div class="form-group row">
                        <label class="font-weight-bold col-form-label  col-xs-6 col-sm-6 col-md-6 col-lg-6 ">Stock Code</label>
                        <select class="form-control  col-xs-6 col-sm-6 col-md-6 col-lg-6" name="stockcode" id="stockcode" (change)="stockCodeChange($event)" [(ngModel)]="stockcode" >
                            <option value="G">G</option>
                            <option value="D">D</option>
                        </select>
                    </div> -->
                    <div class="form-group row">
                        <label class="font-weight-bold col-form-label  col-xs-6 col-sm-6 col-md-6 col-lg-6">Location</label>
                        <input type="text" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="Location" value="" placeholder="Location" />
                    </div>
                    <div class="form-group row">
                        <label class="font-weight-bold col-form-label  col-xs-6 col-sm-6 col-md-6 col-lg-6">Pcs</label>
                        <input type="email" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="psc" value="" placeholder="Pcs" />
                    </div>
                    <div class="form-group row">
                        <label class="font-weight-bold col-form-label  col-xs-6 col-sm-6 col-md-6 col-lg-6">Gross Wt</label>
                        <input type="email" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="gross_wt" value="" placeholder="Gross Wt" />
                    </div>
                    <div class="form-group row">
                        <label class="font-weight-bold col-form-label  col-xs-6 col-sm-6 col-md-6 col-lg-6">Stone Wt</label>
                        <input type="email" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="stone_wt" value="" placeholder="Gross Wt" />
                    </div>
                    <div class="form-group row">
                        <label class="font-weight-bold col-form-label  col-xs-6 col-sm-6 col-md-6 col-lg-6">Net Wt</label>
                        <input type="email" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="net_wt" value="" placeholder="Net Wt" />
                    </div>
                    <div class="form-group row">
                        <label class="font-weight-bold col-form-label  col-xs-6 col-sm-6 col-md-6 col-lg-6">Making Rate</label>
                        <input type="email" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="mkg_rate" value="" placeholder="Making Rate" />
                    </div>
                    <div class="form-group row">
                        <label class="font-weight-bold col-form-label  col-xs-6 col-sm-6 col-md-6 col-lg-6">Making Amount</label>
                        <input type="email" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="net_wt" value="" placeholder="Net Wt" />
                    </div>
                    <div class="form-group row">
                        <label class="font-weight-bold col-form-label  col-xs-6 col-sm-6 col-md-6 col-lg-6">Stone Rate</label>
                        <input type="email" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="net_wt" value="" placeholder="Stone Rate" />
                    </div>
                    <div class="form-group row">
                        <label class="font-weight-bold col-form-label  col-xs-6 col-sm-6 col-md-6 col-lg-6">Stone Amount </label>
                        <input type="email" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="net_wt" value="" placeholder="Stone Amount " />
                    </div>
                    <div class="form-group row">
                        <label class="font-weight-bold col-form-label  col-xs-6 col-sm-6 col-md-6 col-lg-6">Metal Rate</label>
                        <input type="text" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="mtl_rate" value="" placeholder="Metal Rate" />
                    </div>
                    <div class="form-group row">
                        <label class="font-weight-bold col-form-label  col-xs-6 col-sm-6 col-md-6 col-lg-6">Metal Amount</label>
                        <input type="email" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="mtl_amt" value="" placeholder="Metal Amount" />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row ">
                        <div style="height:250px;" class="text-center productImage">
                            <img class="card-img-top" src="assets/images/{{noImage}}" alt="Card image cap" loading="lazy"></div>
                    </div>
                    <div class="form-group row">
                        <label class="font-weight-bold col-form-label  col-xs-6 col-sm-6 col-md-6 col-lg-6">Net Amount</label>
                        <input type="email" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="net_amt" value="" placeholder="Net Amount" />
                    </div>
                    <div class="form-group row">
                        <label class="font-weight-bold col-form-label  col-xs-6 col-sm-6 col-md-6 col-lg-6">VAT %</label>
                        <input type="email" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="vat_per" value="" placeholder="VAT Percentage" />
                    </div>
                    <div class="form-group row">
                        <label class="font-weight-bold col-form-label  col-xs-6 col-sm-6 col-md-6 col-lg-6">VAT Amount</label>
                        <input type="email" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="net_amt" value="" placeholder="VAT Amount" />
                    </div>
                    <div class="form-group row">
                        <label class="font-weight-bold col-form-label  col-xs-6 col-sm-6 col-md-6 col-lg-6">VAT + Net Amount</label>
                        <input type="email" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="net_amt" value="" placeholder="Net Amount" />
                    </div>
                </div>
                <!-- <div class="col-md-6 text-right">
                    <div class="form-group row">
                        <button type="submit" class="btn w-100 btn btn-danger col-xs-6">Close</button>
                        <button type="submit" class="btn w-100 btn btn-primary col-xs-6">Save</button>
                    </div>
                </div> -->
            </div>
            <div style="padding-bottom: 20px;">
                <div style="text-align: right; padding:10px; ">
                    <button class="btn  btn btn-danger" (click)="modal.dismiss('Cross click')">CANCEL</button> &nbsp;
                    <!-- <button class="btn btn-success" type="submit" >Submit</button> -->
                    <button class="btn  btn btn-primary " (click)="formSubmit()">SUBMIT</button>
                </div>
            </div>
        </div>
    </div>
    <!-- /////////////////////////////////////////////////// -->
    <div *ngIf="!showModal">
        <div class="modal-header" style="padding: 10px 20px">
            <h4 class="modal-title" id="modal-dia-metal">POS Diamond Division Detail Screen</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="bg-gray-card">
                        <div class="form-group row">
                            <label class="font-weight-bold col-form-label  col-xs-2 col-sm-2 col-md-2 col-lg-2 ">Stock Code</label>
                            <!-- <input type="text" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="stockcode" value="" placeholder="Stock Code" /> -->
                            <select class="form-control  col-xs-1 col-sm-1 col-md-1 col-lg-1" name="stockcode" id="stockcode" (change)="stockCodeChange($event)" [(ngModel)]="stockcode">
                                        <option value="G">G</option>
                                        <option value="D">D</option>
                                    </select> &nbsp;
                            <!-- <select class="form-control  col-xs-4 col-sm-4 col-md-4 col-lg-4" name="stockcode" id="stockcode"  >
                                        <option value="G">Gold</option>
                                        <option value="D">Diamond</option>
                                    </select> -->
                            <input type="text" class="form-control  col-xs-4 col-sm-4 col-md-4 col-lg-4" id="stockcode" value="" placeholder="Stock Code" /> &nbsp;
                            <input type="text" class="form-control  col-xs-4 col-sm-4 col-md-4 col-lg-4" id="stockcode" value="" placeholder="Stock Desc" />
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-12" style="padding:0px ​15px 20px 15p;">
                        <div class="alert alert-danger alert-dismissible"> Alert : Invalid Quantity </div>
                    </div> -->
                <div class="col-md-6">
                    <!-- <div class="form-group row">
                            <label class="font-weight-bold col-form-label  col-xs-6 col-sm-6 col-md-6 col-lg-6 ">Stock Code</label>
                            <select class="form-control  col-xs-6 col-sm-6 col-md-6 col-lg-6" name="stockcode" id="stockcode" (change)="stockCodeChange($event)" [(ngModel)]="stockcode">
                                <option value="G">G</option>
                                <option value="D">D</option>
                            </select>
                        </div> -->
                    <div class="form-group row">
                        <label class="font-weight-bold col-form-label  col-xs-6 col-sm-6 col-md-6 col-lg-6">Location</label>
                        <input type="text" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="Location" value="" placeholder="Location" />
                    </div>
                    <div class="form-group row">
                        <label class="font-weight-bold col-form-label  col-xs-6 col-sm-6 col-md-6 col-lg-6">Pcs</label>
                        <input type="email" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="psc" value="" placeholder="Pcs" />
                    </div>
                    <div class="form-group row">
                        <label class="font-weight-bold col-form-label  col-xs-6 col-sm-6 col-md-6 col-lg-6">Weight</label>
                        <input type="email" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="weight" value="" placeholder="Weight" />
                    </div>
                    <div class="form-group row">
                        <label class="font-weight-bold col-form-label  col-xs-6 col-sm-6 col-md-6 col-lg-6">Rate</label>
                        <input type="email" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="rate" value="" placeholder="Rate" />
                    </div>
                    <div class="form-group row">
                        <label class="font-weight-bold col-form-label  col-xs-6 col-sm-6 col-md-6 col-lg-6">Discount %</label>
                        <input type="email" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="dic_per" value="" placeholder="Discount Percentage" />
                    </div>
                    <div class="form-group row">
                        <label class="font-weight-bold col-form-label  col-xs-6 col-sm-6 col-md-6 col-lg-6">Discount Amount</label>
                        <input type="email" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="dic_amt" value="" placeholder="Discount Amount" />
                    </div>
                    <div class="form-group row">
                        <label class="font-weight-bold col-form-label  col-xs-6 col-sm-6 col-md-6 col-lg-6">Amount</label>
                        <input type="email" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="amt" value="" placeholder="Amount" />
                    </div>
                    <div class="form-group row">
                        <label class="font-weight-bold col-form-label  col-xs-6 col-sm-6 col-md-6 col-lg-6">VAT %</label>
                        <input type="email" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="vat_per" value="" placeholder="VAT Percentage" />
                    </div>
                    <div class="form-group row">
                        <label class="font-weight-bold col-form-label  col-xs-6 col-sm-6 col-md-6 col-lg-6">VAT Amount</label>
                        <input type="email" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="net_amt" value="" placeholder="VAT Amount" />
                    </div>
                    <div class="form-group row">
                        <label class="font-weight-bold col-form-label  col-xs-6 col-sm-6 col-md-6 col-lg-6">VAT + Net Amount</label>
                        <input type="email" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="vatamt" value="" placeholder="VAT + Amount" />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="font-weight-bold col-form-label  ">Tag Details</label>
                        <!-- <input type="text" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="mtl_rate" value="" placeholder="Metal Rate" /> -->
                        <textarea class="form-control  " rows="8">
                            </textarea>
                    </div>
                    <div class="form-group row text-center">
                        <div style="height:250px;" class="productImage">
                            <img class="card-img-top" src="assets/images/{{noImage}}" alt="Card image cap" loading="lazy"></div>
                    </div>
                </div>
            </div>
            <div style="padding-bottom: 20px;">
                <div style="text-align: right; padding:10px; ">
                    <button class="btn  btn btn-danger" (click)="modal.dismiss('Cross click')">Cancle</button> &nbsp;
                    <!-- <button class="btn btn-success" type="submit" >Submit</button> -->
                    <button class="btn  btn btn-primary " (click)="formSubmit()">Submit</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #oldgoldmodal let-modal modal-lg>
    <div class="modal-header" style="padding: 10px 20px">
        <h4 class="modal-title" id="modal-pos-metal">Old Gold Purchase</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="bg-gray-card">
                    <div class="form-group row">
                        <label class="font-weight-bold col-form-label  col-xs-2 col-sm-2 col-md-2 col-lg-2 ">Stock Code</label>
                        <input type="text" class="form-control col-xs-1 col-sm-1 col-md-1 col-lg-1 text-center border-tr-br-0" id="stock" value="G" placeholder="Stock Code" disabled/>
                        <input type="text" class="form-control  col-xs-4 col-sm-4 col-md-4 col-lg-4 border-tl-bl-0 " id="stockcode" value="" placeholder="Stock Code" /> &nbsp;
                        <input type="text" class="form-control  col-xs-4 col-sm-4 col-md-4 col-lg-4" id="stockcode" value="" placeholder="Stock Desc" />
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-form-label  col-xs-4 col-sm-4 col-md-4 col-lg-4">Pcs</label>
                    <input type="email" class="form-control textboxcorner col-xs-8 col-sm-8 col-md-8 col-lg-8" id="psc" value="" placeholder="" />
                </div>
                <div class="form-group row">
                    <label class="col-form-label col-xs-4 col-sm-4 col-md-4 col-lg-4">Gross Wt</label>
                    <input type="email" class="form-control textboxcorner col-xs-8 col-sm-8 col-md-8 col-lg-8" id="gross_wt" value="" placeholder="" />
                </div>
                <div class="form-group row">
                    <label class="col-form-label col-xs-4 col-sm-4 col-md-4 col-lg-4">Stone Wt</label>
                    <input type="email" class="form-control textboxcorner col-xs-8 col-sm-8 col-md-8 col-lg-8" id="stone_wt" value="" placeholder="" />
                </div>
                <div class="form-group row">
                    <label class="col-form-label col-xs-4 col-sm-4 col-md-4 col-lg-4">Net Wt</label>
                    <input type="email" class="form-control textboxcorner col-xs-8 col-sm-8 col-md-8 col-lg-8" id="net_wt" value="" placeholder="" />
                </div>
                <div class="form-group row">
                    <label class="col-form-label col-xs-4 col-sm-4 col-md-4 col-lg-4">Making Rate</label>
                    <input type="email" class="form-control textboxcorner col-xs-8 col-sm-8 col-md-8 col-lg-8" id="mkg_rate" value="" placeholder="" />
                </div>
                <div class="form-group row">
                    <label class="col-form-label col-xs-4 col-sm-4 col-md-4 col-lg-4">Making Amount</label>
                    <input type="email" class="form-control textboxcorner col-xs-8 col-sm-8 col-md-8 col-lg-8" id="net_wt" value="" placeholder="" />
                </div>
                <div class="form-group row">
                    <label class="col-form-label col-xs-4 col-sm-4 col-md-4 col-lg-4">Stone Rate</label>
                    <input type="email" class="form-control textboxcorner col-xs-8 col-sm-8 col-md-8 col-lg-8" id="net_wt" value="" placeholder="" />
                </div>
                <div class="form-group row">
                    <label class="col-form-label col-xs-4 col-sm-4 col-md-4 col-lg-4">Stone Amount </label>
                    <input type="email" class="form-control textboxcorner col-xs-8 col-sm-8 col-md-8 col-lg-8" id="net_wt" value="" placeholder="" />
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-form-label col-xs-5 col-sm-5 col-md-5 col-lg-5">Metal Rate</label>
                    <input type="text" class="form-control textboxcorner col-xs-7 col-sm-7 col-md-7 col-lg-7" id="mtl_rate" value="" placeholder="" />
                </div>
                <div class="form-group row">
                    <label class="col-form-label col-xs-5 col-sm-5 col-md-5 col-lg-5">Metal Amount</label>
                    <input type="email" class="form-control textboxcorner col-xs-7 col-sm-7 col-md-7 col-lg-7" id="mtl_amt" value="" placeholder="" />
                </div>
                <div class="form-group row">
                    <label class="col-form-label  col-xs-5 col-sm-5 col-md-5 col-lg-5">Purity Difference</label>
                    <input type="email" class="form-control textboxcorner col-xs-7 col-sm-7 col-md-7 col-lg-7" id="net_amt" value="" placeholder="" />
                </div>
                <div class="form-group row">
                    <label class="col-form-label col-xs-5 col-sm-5 col-md-5 col-lg-5">Stone Difference</label>
                    <input type="email" class="form-control textboxcorner col-xs-7 col-sm-7 col-md-7 col-lg-7" id="vat_per" value="" placeholder="" />
                </div>
                <div class="form-group row" style="padding:0px 0px 0px 10px;">
                    <div class="font-weight-bold col-form-label  col-12" style="background-color: #efefef;">Wastage</div>
                    <table class="table">
                        <tbody>
                            <tr>
                                <td> % </td>
                                <td> Quantity </td>
                                <td> Amount </td>
                            </tr>
                            <tr>
                                <td> <input type="email" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="vat_per" value="" placeholder="" /></td>
                                <td> <input type="email" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="vat_per" value="" placeholder="" /></td>
                                <td> <input type="email" class="form-control textboxcorner col-xs-6 col-sm-6 col-md-6 col-lg-6" id="vat_per" value="" placeholder="" /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- <div class="col-md-6 text-right">
                  <div class="form-group row">
                      <button type="submit" class="btn w-100 btn btn-danger col-xs-6">Close</button>
                      <button type="submit" class="btn w-100 btn btn-primary col-xs-6">Save</button>
                  </div>
              </div> -->
        </div>
        <div style="padding-bottom: 20px;">
            <div style="text-align: right; padding:10px; ">
                <button class="btn btn-primary btn-m-r-10" (click)="formSubmit()">CONTINUE</button>
                <button class="btn btn-primary btn-m-r-10" (click)="formSubmit()">SAVE</button>
                <button class="btn btn-danger" (click)="modal.dismiss('Cross click')">CANCEL</button> &nbsp;
                <!-- <button class="btn btn-success" type="submit" >Submit</button> -->
            </div>
        </div>
    </div>
</ng-template>
<ng-template #adjust_sale_return_modal let-modal modal-lg>
    <div class="modal-header" style="padding: 10px 20px">
        <h4 class="modal-title" id="modal-pos-metal">Adjust Sales Return</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">×</span>
  </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-form-label  col-xs-4 col-sm-4 col-md-4 col-lg-4">Branch</label>
                    <input type="email" class="form-control textboxcorner col-xs-8 col-sm-8 col-md-8 col-lg-8" id="psc" value="" placeholder="" />
                </div>
                <div class="form-group row">
                    <label class="col-form-label col-xs-4 col-sm-4 col-md-4 col-lg-4">Fin Year</label>
                    <input type="email" class="form-control textboxcorner col-xs-8 col-sm-8 col-md-8 col-lg-8" id="gross_wt" value="" placeholder="" />
                </div>
                <div class="form-group row">
                    <label class="col-form-label col-xs-4 col-sm-4 col-md-4 col-lg-4">Sales Man</label>
                    <input type="email" class="form-control textboxcorner col-xs-8 col-sm-8 col-md-8 col-lg-8" id="stone_wt" value="" placeholder="" />
                </div>
                <div class="form-group row">
                    <label class="col-form-label col-xs-4 col-sm-4 col-md-4 col-lg-4">Cust Mobile</label>
                    <input type="email" class="form-control textboxcorner col-xs-8 col-sm-8 col-md-8 col-lg-8" id="net_wt" value="" placeholder="" />
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-form-label col-xs-5 col-sm-5 col-md-5 col-lg-5">Voc Type</label>
                    <input type="text" class="form-control textboxcorner col-xs-7 col-sm-7 col-md-7 col-lg-7" id="mtl_rate" value="" placeholder="" />
                </div>
                <div class="form-group row">
                    <label class="col-form-label col-xs-5 col-sm-5 col-md-5 col-lg-5">Vocno</label>
                    <input type="email" class="form-control textboxcorner col-xs-7 col-sm-7 col-md-7 col-lg-7" id="mtl_amt" value="" placeholder="" />
                </div>
                <div class="form-group row">
                    <label class="col-form-label  col-xs-5 col-sm-5 col-md-5 col-lg-5">CustCode</label>
                    <input type="email" class="form-control textboxcorner col-xs-7 col-sm-7 col-md-7 col-lg-7" id="net_amt" value="" placeholder="" />
                </div>
                <div class="form-group row">
                    <label class="col-form-label col-xs-5 col-sm-5 col-md-5 col-lg-5">Voc Date</label>
                    <input type="email" class="form-control textboxcorner col-xs-7 col-sm-7 col-md-7 col-lg-7" id="vat_per" value="" placeholder="" />
                </div>
            </div>
            <div class="col-md-12" style="padding-bottom: 20px;">
                <div style="text-align: right; padding:10px; ">
                    <button class="btn btn-primary btn-m-r-10" (click)="formSubmit()">OK</button>
                    <button class="btn btn-danger" (click)="modal.dismiss('Cross click')">CANCEL</button> &nbsp;
                </div>
            </div>
            <div class="col-md-12">
                <table class="table" id="adjustsalesreturn_table">
                    <tbody>
                        <tr>
                            <th> Voc </th>
                            <th> SalesMan </th>
                            <th> Gross Weight </th>
                            <th> Metal Value </th>
                            <th> Total Value </th>
                            <th> Select</th>
                        </tr>
                        <tr>
                            <td>32</td>
                            <td>Sname</td>
                            <td>100</td>
                            <td>250</td>
                            <td>500</td>
                            <td><input type="checkbox" value=""></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #sales_payment_modal let-modal modal-lg>
    <div class="modal-header" style="padding: 10px 20px">
        <h4 class="modal-title" id="modal-pos-metal">POS Sales Payment</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
  <span aria-hidden="true">×</span>
</button>
    </div>
    <div class="modal-body">
        <mat-tab-group>
            <mat-tab label="CASH RECEIPT">
                <div class="col-md-6" style="margin-top: 20px;margin-bottom:20px;">
                    <div class="form-group row">
                        <label class="col-form-label col-xs-5 col-sm-5 col-md-5 col-lg-5">Receipt Mode</label>
                        <input type="text" class="form-control textboxcorner col-xs-7 col-sm-7 col-md-7 col-lg-7" id="mtl_rate" value="" placeholder="" />
                    </div>
                    <div class="form-group row">
                        <label class="col-form-label col-xs-5 col-sm-5 col-md-5 col-lg-5">Currency</label>
                        <input type="email" class="form-control textboxcorner col-xs-7 col-sm-7 col-md-7 col-lg-7" id="mtl_amt" value="" placeholder="" />
                    </div>
                    <div class="form-group row">
                        <label class="col-form-label  col-xs-5 col-sm-5 col-md-5 col-lg-5">Amount</label>
                        <input type="email" class="form-control textboxcorner col-xs-7 col-sm-7 col-md-7 col-lg-7" id="net_amt" value="" placeholder="" />
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="CREDIT CARD">
            </mat-tab>
            <mat-tab label="ADVANCE">
            </mat-tab>
            <mat-tab label="CUSTOMS">
            </mat-tab>
            <mat-tab label="..."></mat-tab>
        </mat-tab-group>
        <div class="row pos_sales_payment_border">
            <div class="col-md-6" style="margin-top:20px;">
                <div class="form-group row">
                    <label class="col-form-label  col-xs-4 col-sm-4 col-md-4 col-lg-4">Bill Amount</label>
                    <input type="email" class="form-control textboxcorner col-xs-8 col-sm-8 col-md-8 col-lg-8" id="psc" value="" placeholder="" />
                </div>
                <div class="form-group row">
                    <label class="col-form-label col-xs-4 col-sm-4 col-md-4 col-lg-4">Paid Amount</label>
                    <input type="email" class="form-control textboxcorner col-xs-8 col-sm-8 col-md-8 col-lg-8" id="gross_wt" value="" placeholder="" />
                </div>
                <div class="form-group row">
                    <label class="col-form-label col-xs-4 col-sm-4 col-md-4 col-lg-4">Balance</label>
                    <input type="email" class="form-control textboxcorner col-xs-8 col-sm-8 col-md-8 col-lg-8" id="stone_wt" value="" placeholder="" />
                </div>
            </div>
        </div>
        <div style="padding-bottom: 20px;">
            <div style="text-align: right; padding:10px; ">
                <button class="btn btn-primary btn-m-r-10" (click)="formSubmit()">SAVE</button>
                <button class="btn btn-danger" (click)="modal.dismiss('Cross click')">CANCEL</button> &nbsp;
            </div>
        </div>
    </div>
</ng-template>
