import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class SuntechapiService {
  public  httpOptions = {
    headers: new HttpHeaders({
    })};

    constructor(private http: HttpClient) {
    console.log("suntechapi service call ...");
    this.httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': 'somekeytokenwillcomehere'
      })};
  }

  /* Sales Order Services */

  getBranchMaster(): Observable<any> {
    return this.http.get(baseUrl+'BranchMaster');
  }

  getSalesPersonMaster(): Observable<any> {
    return this.http.get(baseUrl+'SalesPersonMaster');
  }

  getOrderType(): Observable<any> {
    return this.http.get(baseUrl+'GeneralMaster?Types=ORDERTYPE%20MASTER');
  }

  getCustomers(): Observable<any> {
    return this.http.get(baseUrl+'AccountMaster?AccountMode=P,R&fields=ACCODE,ACCOUNT_HEAD');
  }

  getCustomerDetails(acCode): Observable<any> {
    return this.http.get(baseUrl+'AccountMaster?strAccode='+acCode);
  }

  getAllColors(): Observable<any> {
    return this.http.get(baseUrl+'GeneralMaster?Types=COLOR%20MASTER');
  }

  getAllShapes(): Observable<any> {
    return this.http.get(baseUrl+'GeneralMaster?Types=SHAPE%20MASTER');
  }

  getAllSize(): Observable<any> {
    return this.http.get(baseUrl+'GeneralMaster?Types=SIZE%20MASTER');
  }

  getAllClarity(): Observable<any> {
    return this.http.get(baseUrl+'GeneralMaster?Types=CLARITY%20MASTER');
  }

  getEnamelColors(): Observable<any> {
    return this.http.get(baseUrl+'GeneralMaster?Types=ENAMEL%20COLOR');
  }

  getKarats(): Observable<any> {
    return this.http.get(baseUrl+'karatMaster');
  }

  getDesignProperties(strDesignCode = "MB002293"): Observable<any> {
    return this.http.get(baseUrl+'SalesOrder?strDesignCode='+strDesignCode+'&strIsDesignStock=N&strIsComponent=Y&strIsColorSet=N&strIsMetalColor=%22%22');
  }

  getSalesOrderStock(catCode = "000"): Observable<any> {
    return this.http.get(baseUrl+'SalesOrderStockMain?strCatalogueCode='+catCode);
  }

  getCatalogueList(): Observable<any> {
    return this.http.get(baseUrl+'CatalogueLookup');
  }

  getProductDetailsbyStockCode(stockCode = "MR011317"): Observable<any> {
    return this.http.get(baseUrl+'SalesOrderStockMain?strStockCode='+stockCode);
  }

  /*getProducts_SalesOrderEdit(sessionId = ""): Observable<any> {
    return this.http.get(baseUrl+'SalesOrderEdit?SESSION_ID='+sessionId);
  }*/

  getProducts_SalesOrderEdit(sessionId = ""): Observable<any> {
    return this.http.get(baseUrl+'SalesOrderEdit/GetsaleorderTemp?SESSION_ID='+sessionId);
  }


  getProducts_SalesOrderEditComponents(sessionId = ""): Observable<any> {
    return this.http.get(baseUrl+'SalesOrderEditComponents?strsessionId='+sessionId);
  }

  getProducts_SalesOrderEditComponents_by_designCode(sessionId = "", designCode=""): Observable<any> {
    return this.http.get(baseUrl+'SalesOrderEditComponents?strsessionId='+sessionId+"&strDesignCode="+designCode);
  }

  /*getProducts_SalesOrderEditComponents_by_designCode_unq_design_id(sessionId = "", designCode="", strUNQ_DESIGN_ID=""): Observable<any> {
    return this.http.get(baseUrl+'SalesOrderEditComponents?strsessionId='+sessionId+"&strDesignCode="+designCode+"&strUNQ_DESIGN_ID="+strUNQ_DESIGN_ID);
  } */

  getProducts_SalesOrderEditComponents_by_designCode_unq_design_id(sessionId = "", designCode="", strUNQ_DESIGN_ID=""): Observable<any> {
    return this.http.get(baseUrl+'SalesOrderEditComponents/GetsalesOrderStmtlDetailsTemp?strsessionId='+sessionId+"&strDesignCode="+designCode+"&strUNQ_DESIGN_ID="+strUNQ_DESIGN_ID);
  }

  get_SalesOrderEdit_UID(sessionId, strStockCode){
    return this.http.get(baseUrl+'SalesOrderEdit?SESSION_ID='+sessionId+'&strStockCode='+strStockCode);
  }

  getAllSalesOrders(): Observable<any> {
    return this.http.get(baseUrl+'SalesOrder');
  }

  /* Delete API */
  /*deleteProduct_SalesOrderEdit(sessionId = "", designCode = "", UniqueDesignID=""): Observable<any> {
    return this.http.delete(baseUrl+'SalesOrderEdit?SESSION_ID='+sessionId+'&DESIGN_CODE='+designCode+'&UniqueDesignID='+UniqueDesignID);
  }

  deleteProduct_SalesOrderEdit_bySessionID(sessionId = ""): Observable<any> {
    return this.http.delete(baseUrl+'SalesOrderEdit?SESSION_ID='+sessionId);
  }

  deleteProduct_SalesOrderEditComponents(sessionId = "", designCode = "", UNQ_DESIGN_ID=""): Observable<any> {
    return this.http.delete(baseUrl+'SalesOrderEditComponents?SESSION_ID='+sessionId+'&strDesignCode='+ designCode+'&UNQ_DESIGN_ID='+UNQ_DESIGN_ID);
  } */


  deleteProduct_SalesOrderEdit(sessionId = "", designCode = "", UniqueDesignID=""): Observable<any> {
    return this.http.get(baseUrl+'SalesOrderEdit/Delete?SESSION_ID='+sessionId+'&DESIGN_CODE='+designCode+'&UniqueDesignID='+UniqueDesignID);
  }

  deleteProduct_SalesOrderEdit_bySessionID(sessionId = ""): Observable<any> {
    return this.http.get(baseUrl+'SalesOrderEdit/Delete?SESSION_ID='+sessionId);
  }

  deleteProduct_SalesOrderEditComponents(sessionId = "", designCode = "", UNQ_DESIGN_ID=""): Observable<any> {
    return this.http.get(baseUrl+'SalesOrderEditComponents/Delete?SESSION_ID='+sessionId+'&strDesignCode='+ designCode+'&UNQ_DESIGN_ID='+UNQ_DESIGN_ID);
  }



  /* Put API */

  /*editProduct_SalesOrder(sessionId = "", designCode = "", UNQ_DESIGN_ID="",COMPSLNO=1, records): Observable<any> {
    return this.http.put(baseUrl+'SalesOrderEdit?SESSION_ID='+sessionId+'&DESIGN_CODE='+ designCode+'&UNQ_DESIGN_ID='+UNQ_DESIGN_ID+'&COMPSLNO='+COMPSLNO, records, this.httpOptions);
  } */

  editProduct_SalesOrder(sessionId = "", designCode = "", UNQ_DESIGN_ID="",COMPSLNO=1, records): Observable<any> {
    return this.http.put(baseUrl+'SalesOrderEdit/UpdateItemAttribute?SESSION_ID='+sessionId+'&DESIGN_CODE='+ designCode+'&UNQ_DESIGN_ID='+UNQ_DESIGN_ID, records, this.httpOptions);
  }



 editProduct_SalesOrderEditComponents(sessionId = "", designCode = "", UNQ_DESIGN_ID="",COMPSLNO=1, records): Observable<any> {
    return this.http.put(baseUrl+'SalesOrderEditComponents?SESSION_ID='+sessionId+'&DESIGN_CODE='+ designCode+'&UNQ_DESIGN_ID='+UNQ_DESIGN_ID+'&COMPSLNO='+COMPSLNO, records, this.httpOptions);
  }

  updateKaratColorinWishlist(SESSION_ID="",KaratCode="", Color="") {
    return this.http.put(baseUrl+'SalesOrderEdit?SESSION_ID='+SESSION_ID+'&KaratCode='+ KaratCode+'&Color='+Color, this.httpOptions);
  }

  /* Post API */
  postProductComponents(records): Observable<any> {
    return this.http.post(baseUrl+'SalesOrderEditComponents' , records, this.httpOptions);
  }

  postProduct_SalesOrderEdit(record): Observable<any> {
    return this.http.post(baseUrl+'SalesOrderEdit' , record, this.httpOptions);
  }

  postSalesOrder(records): Observable<any> {
    return this.http.post(baseUrl+'SalesOrder' , records, this.httpOptions);
  }

   /* Sales Order Services End */

   /* Company Parameter */
  getCompanyParameters(): Observable<any> {
    return this.http.get(baseUrl+'CompanyParameters');
  }

  /* Login */
  getUserName(username): Observable<any> {
    return this.http.get(baseUrl+'UserDetailNetMaster?USERNAME='+username);
  }

  checkUserNamePassword(username,password): Observable<any> {
    return this.http.get(baseUrl+'ValidatePassword?strusername='+username+'&strPassword='+password);
  }

  getUserBranch(username): Observable<any> {
    return this.http.get(baseUrl+'UseBranchNetMaster?USERNAME='+username);
  }

  getUserBranchDetails(branchcode): Observable<any> {
    return this.http.get(baseUrl+'BranchMaster?BranchCode='+branchcode);
  }

  getUFinancialYear(branchcode, username): Observable<any> {
    return this.http.get(baseUrl+'FinancialYear?branchcode=HO&strusername=Admin');
  }

  /* Menus */
  getLeftMenus(): Observable<any> {
    return this.http.get(baseUrl+'MenuListLeftPan?strRoleCode=Admin&strusername=Admin&branchcode=HO&strlanguage=ENGLISH&strCountry=SAU');
  }

  getMenusPage(): Observable<any> {
    return this.http.get(baseUrl+'MenuPage?branchcode=HO&strusername=ADMIN&strbuttonID=1&strRoleId=00001');
  }

  /* POS started on 20-09-2021 */
  getPOS_Stockdetails_withoutcost(stock_code): Observable<any> {
    return this.http.get(baseUrl+'TableViewWithoutCost?STOCK_CODE='+stock_code);
  }

  getPOS_Stockdetails_branchwise_withcost(stock_code, branch_code): Observable<any> {
    return this.http.get(baseUrl+'BranchDiamondCost?BDC_STOCK_CODE='+stock_code+'&BDC_BRANCH_CODE='+branch_code);
  }

  getDivisionDetails(): Observable<any> {
    return this.http.get(baseUrl+'DivisionMaster');
  }





}
