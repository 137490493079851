import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {IOption} from 'ng-select';
import { environment } from '../../../environments/environment';
import { SharedService } from '../../shared.service';

@Component({
  selector: 'app-demopos',
  templateUrl: './demopos.component.html',
  styleUrls: ['./demopos.component.scss']
})
export class DemoposComponent implements OnInit {
  public noImage = environment._noImage;
  showModal: boolean = true;
  stockcode: any;
  selectedOption = '3';
  simpleOption: Array<IOption> = [
    {value: '0', label: 'Alabama'},
    {value: '1', label: 'Wyoming'},
    {value: '2', label: 'Coming'},
    {value: '3', label: 'Henry Die'},
    {value: '4', label: 'John Doe'}
  ];

  title = 'appBootstrap';

  closeResult: string;

  constructor(private modalService: NgbModal) {
    this.stockcode = "G";
  }

  ngOnInit(): void {
  }

  open(content) {
    this.modalService.open(content, {size: 'lg', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }




  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  stockCodeChange(event){
    console.log(event.target.value)
    this.stockcode = event.target.value;
    if(event.target.value == "G")
    this.showModal = true;
    else
    this.showModal = false;

  }

}
